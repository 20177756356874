.Step {
  &__TextWrap {
    margin-bottom: var(--step-header-mb, 28px);

    &__Title {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.15px;
    }

    &__Desc {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.1px;
      margin-top: 4px;
    }
  }

  &__Info {
    background-color: var(--color-footer-default);
    display: flex;
    column-gap: 8px;
    margin: 36px auto 0;
    padding: var(--space-10);
    max-width: var(--step-info-max-width);
  }
}
