.OnboardingProgress {
  margin-bottom: var(--progress-bar-mb, var(--space-24));
  height: 60px;
  position: relative;

  &__Content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
